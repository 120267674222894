/*
 * source: https://github.com/GoogleChrome/web-vitals/blob/main/src/lib/onHidden.ts
 */

export const onHidden = (cb: () => void) => {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      cb();
    }
  });
};
