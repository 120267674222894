import React from 'react';
import ReactJson from 'react-json-view';

const defaultJson = {};

const JsonViewer: React.FC<{ value: Record<string, any> }> = ({ value }) => {
  const isJson = Object.prototype.toString.call(value) === '[object Object]';

  const json = isJson ? value : defaultJson;

  return (
    <ReactJson
      src={json}
      theme="paraiso"
      displayDataTypes={false}
      defaultValue={{
        value: 'Unknown Error'
      }}
      collapsed={1}
      name={false}
    />
  );
};

export default JsonViewer;
