export default function deepCheckValues(value1?: any, value2?: any): boolean {
  if (value1 === value2) {
    return true;
  }
  const isValue1Object = typeof value1 === 'object';
  const isValue2Object = typeof value2 === 'object';
  let result: boolean;
  const setResult = (value: boolean) => {
    if (result !== false) {
      result = value;
    }
  };
  if (!isValue1Object || !isValue2Object) {
    setResult(value1 === value2);
  } else if (Array.isArray(value1)) {
    if (value1.length !== value2.length || !Array.isArray(value2)) {
      setResult(false);
    } else {
      setResult(
        value1.every((item, index) => deepCheckValues(item, value2[index]))
      );
    }
  } else {
    const value1Keys = Object.keys(value1);
    const value2Keys = Object.keys(value2);

    if (value1Keys.length !== value2Keys.length) {
      setResult(false);
    } else {
      setResult(
        Object.keys(value1).every((key) =>
          deepCheckValues(value1?.[key], value2?.[key])
        )
      );
    }
  }
  return !!result;
}
