import React, { useCallback, useEffect, useMemo } from 'react';
import microfrontendsRenderTreeManagerContext from '../microfrontendsRenderTreeManagerContext';
import * as T from './types';
import { RenderBranchOriginType, RenderBranchType } from '../types';

const MicrofrontendsRenderTreeManagerProvider: React.FC<
  T.MicrofrontendsRenderTreeManagerProviderPropsType
> = ({
  children,
  assetReference,
  firstComponentName,
  firstComponentDisplayName
}) => {
  const {
    addNestedBranch: addNestedBranchToParent,
    removeNestedBranch: removeNestedBranchFromParent,
    branchLevel: parentBranchLevel,
    getRenderTree: getRenderTreeFromParent,
    getBranchOrigin: getBranchOriginFromParent
  } = React.useContext(microfrontendsRenderTreeManagerContext) || {};

  const branches = useMemo(() => [], []);
  const branchLevel =
    typeof parentBranchLevel === 'number' ? parentBranchLevel + 1 : 0;

  const renderBranch: RenderBranchType = useMemo(() => {
    return {
      assetReference,
      branchLevel,
      branches
    };
  }, [assetReference, branchLevel, branches]);

  useEffect(() => {
    if (
      typeof addNestedBranchToParent === 'function' &&
      typeof removeNestedBranchFromParent === 'function'
    ) {
      addNestedBranchToParent?.(renderBranch);

      return () => {
        removeNestedBranchFromParent?.(renderBranch);
      };
    }
  }, [addNestedBranchToParent, removeNestedBranchFromParent, renderBranch]);

  const addNestedBranch = useCallback(
    (nestedBranch: RenderBranchType) => {
      if (!branches.includes(nestedBranch)) {
        branches.push(nestedBranch);
      }
    },
    [branches]
  );

  const removeNestedBranch = useCallback(
    (nestedBranch: RenderBranchType) => {
      const index = branches.indexOf(nestedBranch);
      if (index > -1) {
        branches.splice(index, 1);
      }
    },
    [branches]
  );

  const getRenderTree = useCallback(() => {
    if (typeof getRenderTreeFromParent === 'function') {
      return getRenderTreeFromParent();
    } else {
      return renderBranch;
    }
  }, [getRenderTreeFromParent, renderBranch]);

  const getBranchOrigin = useCallback(() => {
    const branch: RenderBranchOriginType = {
      assetReference,
      branchLevel
    };

    if (typeof getBranchOriginFromParent === 'function') {
      const parentBranch = getBranchOriginFromParent();

      if (parentBranch) {
        branch.parentBranch = parentBranch;
      }
    }

    return branch;
  }, [getBranchOriginFromParent, assetReference, branchLevel]);

  return (
    <microfrontendsRenderTreeManagerContext.Provider
      value={{
        assetReference,
        branchLevel,
        addNestedBranch,
        removeNestedBranch,
        getRenderTree,
        getBranchOrigin,
        firstComponentName,
        firstComponentDisplayName
      }}
    >
      {children}
    </microfrontendsRenderTreeManagerContext.Provider>
  );
};

export default MicrofrontendsRenderTreeManagerProvider;
