import React, { useMemo } from 'react';
import * as S from './styles';
import * as T from './types';
import * as C from './components';
import { useMicrofrontendsRenderTreeManager } from 'src/contexts/microfrontendsRenderTreeManager';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

const ErrorComponentLoader: React.FC<T.ErrorComponentLoaderPropsType> = (
  props
) => {
  const { getBranchOrigin } = useMicrofrontendsRenderTreeManager();
  const { northboundAPIs } = useDependencyManagerContext();

  const { contentAssetReference, layoutAssetReference } = useMemo(() => {
    const mfeRouterState = northboundAPIs.v1.microfrontendRouter.getState();

    return {
      layoutAssetReference: mfeRouterState.layout?.assetReference as string,
      contentAssetReference: mfeRouterState.content?.assetReference as string
    };
  }, [northboundAPIs.v1.microfrontendRouter]);

  const ErrorComponent = useMemo(() => {
    const branchOrigin = getBranchOrigin();

    let isMainContent = branchOrigin?.branchLevel === 0;
    const isContentAssetReference =
      contentAssetReference === branchOrigin?.assetReference;

    if (!isMainContent && layoutAssetReference && isContentAssetReference) {
      let count = 0;
      const isSelfNested = (branch?: typeof branchOrigin) => {
        if (contentAssetReference === branch?.assetReference) {
          count++;
        }
        if (branch?.parentBranch) {
          isSelfNested(branch?.parentBranch);
        }
      };
      isSelfNested(branchOrigin);
      isMainContent = count === 1;
    }

    return isMainContent ? C.FullErrorDisplay : C.CompactErrorDisplay;
  }, [contentAssetReference, getBranchOrigin, layoutAssetReference]);

  return (
    <S.Container>
      <ErrorComponent {...props} />
    </S.Container>
  );
};

export default ErrorComponentLoader;
