import React from 'react';
import * as T from './types';

const checkStyles = (style: React.CSSProperties = {}) => {
  for (const [property, value] of Object.entries(style)) {
    if (!CSS.supports(property, value)) {
      return false;
    }
  }
  return true;
};

function StyleSupportWrapper(props: T.WrapperProps, ref: React.Ref<Element>) {
  const { as, style, children, ...elementProps } = props;

  if (!checkStyles(style)) {
    return <>{children}</>;
  }

  const WrapperElement = as || 'div';

  return (
    <WrapperElement
      ref={ref}
      style={style}
      {...elementProps}
    >
      {children}
    </WrapperElement>
  );
}

const WrapperWithForwardedRef: <E extends React.ElementType = 'div'>(
  props: T.StyleSupportWrapperProps<E>
) => React.ReactElement | null = React.forwardRef(StyleSupportWrapper);

export default WrapperWithForwardedRef;
