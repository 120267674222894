import React from 'react';
import * as T from './types';

export default function useAsyncMemo(
  callback: T.UseAsyncMemoCallbackType,
  dependencies?: T.UseAsyncMemoDependenciesType
): T.UseAsyncMemoReturnType {
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<any>(null);
  const deps = Array.isArray(dependencies) ? dependencies : [];

  const refresh = React.useCallback(() => {
    const setErrorOrData = (options: { error?: any; data?: any }) => {
      const error = options?.data ? null : options?.error;
      const data = error ? null : options?.data;

      setData(() => data);
      setError(() => error);
      setLoading(false);
    };

    callback()
      .then((result: any) => setErrorOrData({ data: result }))
      .catch((error: any) => setErrorOrData({ error }));
  }, [callback]);

  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { data, loading, error, refresh };
}
