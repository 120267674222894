import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;
  height: 1.5rem;
`;

export const Content = styled.button`
  all: unset;
  display: flex;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  gap: 0 0.25em;
  flex-wrap: wrap;
  overflow: hidden;
  max-width: 100%;
`;
