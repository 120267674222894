import React from 'react';

import { useMicrofrontendsRenderTreeManager } from 'src/contexts/microfrontendsRenderTreeManager';
import StyleSupportWrapper from 'src/components/StyleSupportWrapper';

import {
  ASSET_REFERENCE_SYMBOL,
  getBranchLevel,
  mfeTree,
  reportCallback
} from 'src/utils/webProfiler';

import {
  CLSHandler,
  INPHandler,
  LCPHandler,
  MLSHandler
} from 'src/utils/performanceHandlers';

import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

import * as T from './types';

CLSHandler.setup(reportCallback);
INPHandler.setup(reportCallback);
LCPHandler.setup(reportCallback);
MLSHandler.setup(reportCallback);

const WebProfiler: React.FC = ({ children }) => {
  const containerRef = React.useRef<T.ProfilingContainer>(null);
  const { getBranchOrigin, assetReference } =
    useMicrofrontendsRenderTreeManager();
  const { northboundAPIs } = useDependencyManagerContext();

  React.useEffect(() => {
    CLSHandler.enable();
    INPHandler.enable();
    LCPHandler.enable();
    MLSHandler.enable();
  }, []);

  React.useEffect(() => {
    let origin = getBranchOrigin();
    const tree = [origin.assetReference];
    while (origin.parentBranch) {
      tree.unshift(origin.parentBranch.assetReference);
      origin = origin.parentBranch;
    }
    mfeTree.set(assetReference, tree);

    if (containerRef.current) {
      containerRef.current[ASSET_REFERENCE_SYMBOL] = assetReference;
    }
  }, [assetReference, getBranchOrigin]);

  const isMainContent = React.useMemo(
    () => getBranchLevel(northboundAPIs, getBranchOrigin) <= 1,
    [northboundAPIs, getBranchOrigin]
  );

  if (isMainContent) {
    return (
      <StyleSupportWrapper
        ref={containerRef}
        style={{
          display: 'contents'
        }}
      >
        {children}
      </StyleSupportWrapper>
    );
  }

  return <>{children}</>;
};

export default WebProfiler;
