import styled from 'styled-components';

export const ModalTitle = styled.p`
  margin: 0;
  color: #fff;
  margin-top: auto;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  font-family: sans-serif;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  overflow: hidden;
  box-sizing: border-box;
  word-wrap: break-word;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 0.5rem;
`;

export const FloatingButton = styled.button`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    background-color: #000000;
    border-radius: 50%;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0.25em;
  border: 1px solid #000000;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-bottom: auto;

  .--warn & {
    background-color: #ffd700;
  }
  .--error & {
    color: #e7e9db;
    background-color: #2f1e2e;
  }

  h3 {
    margin-top: 0;
  }
  details {
    margin-bottom: 0.5rem;
    p {
      font-family: monospace;
    }
    p:first-child {
      margin-top: 0;
    }
  }
  dl {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    row-gap: 0.5rem;
    column-gap: 0.25rem;
  }
  dt {
    font-weight: bold;
    text-align: right;
    font-size: 0.8rem;
    &::after {
      content: ':';
    }
  }
  dd {
    margin: 0;
    font-family: monospace;
  }
`;
