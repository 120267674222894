import {
  initInteractionCountPolyfill,
  whenIdle,
  onHidden,
  onBFCacheRestore
} from 'src/utils/googleWebVitals';

import {
  DEFAULT_DURATION_THRESHOLD,
  estimateP98LongestInteraction,
  processInteractionEntry,
  resetInteractions
} from 'src/utils/googleWebVitals/interactions';

import {
  type ReportCallback,
  createNavigationCallback,
  groupEntriesByAssetReference,
  mfeTree
} from 'src/utils/webProfiler';

import { PerformanceHandler } from './PerformanceHandler';

initInteractionCountPolyfill();

export class INPHandler extends PerformanceHandler {
  private value: number = 0;
  private entries: PerformanceEventTiming[] = [];

  static setup(onINP: ReportCallback): void {
    if (this.$instance) {
      return;
    }
    this.$instance = new INPHandler(onINP);
    this.$instance.observer?.observe({
      type: 'event',
      buffered: true,
      durationThreshold: DEFAULT_DURATION_THRESHOLD
    } as PerformanceObserverInit);

    this.$instance.observer?.observe({ type: 'first-input', buffered: true });
    onHidden(() => {
      this.$instance.handle(this.$instance.observer?.takeRecords());
    });

    // Reset interaction counters on bfcache restore.
    onBFCacheRestore(() => {
      resetInteractions();
    });
  }

  constructor(onINP: ReportCallback) {
    super((list) => {
      INPHandler.$instance?.handle(list.getEntries());
    });
    this.onReport = onINP;
    createNavigationCallback(() => {
      resetInteractions();
    });
  }

  handle(entriesList: PerformanceEntry[]): void {
    const filteredEntries = entriesList.filter(
      (entry) => (entry as { interactionId?: number })?.interactionId
    );
    whenIdle(() => {
      const group = groupEntriesByAssetReference(filteredEntries);
      for (const [assetReference, entries] of group.entries()) {
        entries.forEach((entry) => {
          processInteractionEntry(entry as PerformanceEventTiming);
        });
        const inp = estimateP98LongestInteraction();
        if (inp && inp.latency !== this.value) {
          this.value = inp.latency;
          this.entries = inp.entries;
        }
        if (this.value > 0 && this.entries.length > 0) {
          this.report({
            metric: 'INP',
            tree: mfeTree.get(assetReference),
            userAgent: window.navigator.userAgent,
            value: this.value,
            entries: this.entries,
            assetReference,
            timestamp: performance.now()
          });
        }
      }
    });
  }
}
