import React from 'react';
import * as S from './styles';
import { ErrorComponentLoaderPropsType } from 'src/components/ErrorBoundary/ErrorComponentLoader/types';

const BigErrorComponent: React.FC<ErrorComponentLoaderPropsType> = ({
  resetComponent
}: ErrorComponentLoaderPropsType) => {
  return (
    <S.Container>
      <S.Content>
        <h2>Error</h2>
        <br />
        <h4>Something went wrong</h4>
        <br />
        {resetComponent && (
          <>
            <p>
              Please click on the button to try again. If the error persists,
              try restarting the application or contact us.
            </p>
            <br />
            <S.Button onClick={resetComponent}>Refresh</S.Button>
          </>
        )}
      </S.Content>
    </S.Container>
  );
};

export default BigErrorComponent;
