import styled from 'styled-components';

export const ShowModalButton = styled.button<{ count?: number }>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // Remove button css
  display: inline-block;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: inherit;
  appearance: none;

  * {
    margin: 0;
    padding: 0;
  }

  &:not(:hover) {
    p {
      display: none;
    }
  }
`;
