/*
 * source: https://github.com/GoogleChrome/web-vitals/blob/main/src/lib/whenIdle.ts
 */

import { onHidden } from './onHidden';
import { runOnce } from './runOnce';

/**
 * Runs the passed callback during the next idle period, or immediately
 * if the browser's visibility state is (or becomes) hidden.
 */
export const whenIdle = (cb: () => void): number => {
  const rIC = self.requestIdleCallback || self.setTimeout;

  let handle = -1;
  const callback = runOnce(cb);
  // If the document is hidden, run the callback immediately, otherwise
  // race an idle callback with the next `visibilitychange` event.
  if (document.visibilityState === 'hidden') {
    callback();
  } else {
    handle = rIC(callback);
    onHidden(callback);
  }
  return handle;
};
