import React, { useMemo, useState } from 'react';
import Modal from '../Modal';
import * as T from './types';
import * as S from './styles';
import { IconWarningAlt } from '@veneer/core';
import JsonViewer from '../JsonViewer';

const ModalStack: React.FC<T.ModalStackPropsType> = ({ list }) => {
  const [modalStack, setModalStack] = useState<typeof list>([]);

  const modalStackSize = modalStack?.length || 0;
  const isModalStackEmpty = !(modalStackSize > 0);
  const listSize = list?.length || 0;
  const isListEmpty = !(listSize > 0);
  const currentErrorNumber = listSize - modalStackSize + 1;
  const currentModal = modalStack?.[0];

  function closeFirstModal() {
    setModalStack(modalStack?.slice(1));
  }

  function hideModals() {
    setModalStack([]);
  }

  const modalDetails = useMemo(() => {
    return {
      type: currentModal?.type,
      assetReference: currentModal?.branchOrigin?.assetReference,
      value: {
        ['Error Details']: currentModal?.componentStackModuleOrigin,
        ['Original Error']: {
          message: currentModal?.error?.message,
          stack: currentModal?.error?.stack?.split('\n')
        }
      }
    };
  }, [currentModal]);

  return (
    <>
      {!isListEmpty && (
        <S.ShowModalButton
          count={list?.length}
          onClick={() => setModalStack(list)}
        >
          <p>{list?.length}</p>
          <IconWarningAlt
            filled
            color="#ff6666"
            size={36}
          />
        </S.ShowModalButton>
      )}
      {!isModalStackEmpty && (
        <Modal
          title={`Error ${currentErrorNumber} of ${listSize}`}
          type={modalDetails?.type}
          onCloseClick={() => closeFirstModal()}
          onOutsideClick={() => closeFirstModal()}
          onCloseAllClick={() => hideModals()}
        >
          <h3>Error found at {modalDetails?.assetReference}</h3>
          <JsonViewer value={modalDetails?.value} />
        </Modal>
      )}
    </>
  );
};

export default ModalStack;
