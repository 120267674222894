import {
  type ReportCallback,
  groupEntriesByAssetReference,
  mfeTree
} from 'src/utils/webProfiler';

import { getVisibilityWatcher } from 'src/utils/googleWebVitals';

import { PerformanceHandler } from './PerformanceHandler';

const reportedMetrics: Record<string, boolean> = {};

export class LCPHandler extends PerformanceHandler {
  private isListening: boolean = true;
  private visibilityWatcher: ReturnType<typeof getVisibilityWatcher>;
  private instanceTimestamp: number = performance.now();

  static setup(onLCP: ReportCallback): void {
    if (this.$instance) {
      return;
    }
    this.$instance = new LCPHandler(onLCP);
    this.$instance.observer?.observe({
      type: 'largest-contentful-paint',
      buffered: true
    });
  }

  constructor(onLCP: ReportCallback) {
    super((list: PerformanceObserverEntryList) => {
      LCPHandler.$instance?.handle(list.getEntries());
    });

    this.onReport = (performanceReport) => {
      const entries = performanceReport.entries as LargestContentfulPaint[];
      reportedMetrics[entries[0].id] = true;
      onLCP(performanceReport);
    };
    this.visibilityWatcher = getVisibilityWatcher();

    // LCP must be disabled after input.
    window.addEventListener('keydown', () => this.stop.bind(this), true);
    window.addEventListener('click', () => this.stop.bind(this), true);
  }

  stop(): void {
    this.isListening = false;
  }

  handle(entries: PerformanceEntry[]): void {
    if (!this.isListening) {
      return;
    }

    const group = groupEntriesByAssetReference(entries);

    for (const [assetReference, entries] of group.entries()) {
      const latestEntry = entries.sort(
        (a, b) => b.startTime - a.startTime
      )[0] as LargestContentfulPaint;

      if (
        latestEntry &&
        latestEntry.startTime < this.visibilityWatcher.firstHiddenTime
      ) {
        this.report({
          metric: 'LCP',
          userAgent: window.navigator.userAgent,
          tree: mfeTree.get(assetReference),
          assetReference,
          value: Math.max(latestEntry.startTime - this.instanceTimestamp, 0),
          referenceTime: this.instanceTimestamp,
          entries: [latestEntry],
          timestamp: performance.now()
        });
      }
    }
  }
}
