import React, { useCallback, useContext, useState } from 'react';
import logManagerContext from '../logManagerContext';
import * as T from './types';
import { useMicrofrontendsRenderTreeManager } from 'src/contexts/microfrontendsRenderTreeManager';
import type {
  CoreLogEntryType,
  LogEntryType
} from 'src/contexts/logManager/types';
import ModalStack from './components/ModalStack';

function composeConsoleMessage(options: CoreLogEntryType): any[] {
  const { error, componentStack, branchOrigin } = options;
  return [
    `Error at ${branchOrigin?.assetReference}.`,
    '\n Error details: ',
    {
      ['Module Origin']: branchOrigin,
      ['Component Stack']: componentStack
    },
    '\n Original error: \n',
    error
  ];
}

const LogManagerProvider: React.FC<T.LogManagerProviderPropsType> = ({
  children,
  uiOverlay
}) => {
  const { log: ParentLog } = useContext(logManagerContext) || {};
  const { branchLevel, getBranchOrigin } = useMicrofrontendsRenderTreeManager();
  const isUiOverlayEnabled = !!uiOverlay?.enable;

  const [logEntries, setLogEntries] = useState<CoreLogEntryType[]>([]);

  const log = useCallback(
    (entry: CoreLogEntryType) => {
      if (branchLevel > 0) {
        ParentLog(entry);
      } else {
        const message = composeConsoleMessage(entry);

        console?.[entry?.type]?.(...message);

        const isOverlayErrorType = entry.type === 'error';
        if (isUiOverlayEnabled && isOverlayErrorType) {
          setLogEntries([...logEntries, entry]);
        }
      }
    },
    [branchLevel, ParentLog, logEntries, isUiOverlayEnabled]
  );

  const logError = useCallback(
    (entry: LogEntryType) => {
      const branchOrigin = getBranchOrigin?.();
      log({
        type: 'error',
        branchOrigin,
        ...entry
      });
    },
    [getBranchOrigin, log]
  );

  return (
    <logManagerContext.Provider value={{ logError, log }}>
      {children}
      {isUiOverlayEnabled && <ModalStack list={logEntries} />}
    </logManagerContext.Provider>
  );
};

export default LogManagerProvider;
