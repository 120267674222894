import React, { useRef } from 'react';
import * as T from './types';
import * as S from './styles';

const Modal: React.FC<T.ModalPropsType> = ({
  title,
  children,
  type,
  onCloseClick,
  onCloseAllClick,
  onOutsideClick
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const closeAllRef = useRef<HTMLButtonElement>(null);

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const modalContainer = containerRef?.current;
    const modalContent = contentRef?.current;

    const isContainerArea =
      target === modalContainer || modalContainer?.contains?.(target);

    const isContentArea =
      target === modalContent || modalContent?.contains?.(target);
    const isCloseButton = target === closeButtonRef?.current;
    const isCloseAllButton = target === closeAllRef?.current;

    const isInvalidArea = isContentArea || isCloseButton || isCloseAllButton;

    if (isContainerArea && !isInvalidArea) {
      onOutsideClick?.();
    }
  };

  return (
    <S.Container
      className={`--${type}`}
      ref={containerRef}
      onClick={handleContainerClick}
    >
      <S.ButtonsWrapper>
        {onCloseClick && (
          <S.FloatingButton
            onClick={() => onCloseClick?.()}
            ref={closeButtonRef}
          >
            {'\u21A9'}
          </S.FloatingButton>
        )}
        {onCloseAllClick && (
          <S.FloatingButton
            onClick={() => onCloseAllClick?.()}
            ref={closeAllRef}
          >
            {'\u00D7'}
          </S.FloatingButton>
        )}
      </S.ButtonsWrapper>
      <S.ModalTitle>{title}</S.ModalTitle>
      <S.ModalContent
        ref={contentRef}
        className="modal-content"
      >
        {children}
      </S.ModalContent>
    </S.Container>
  );
};

export default Modal;
