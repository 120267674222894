import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;

  * {
    font-family: 'Forma DJR UI', Arial, sans-serif;
  }

  p,
  h2,
  h4 {
    color: #333;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;

    @media (min-width: 576px) and (max-width: 991px) {
      font-size: 14px;
      line-height: 24px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  h2 {
    font-size: 56px;
    line-height: 64px;
    font-weight: 400;

    @media (min-width: 576px) and (max-width: 991px) {
      font-size: 40px;
      line-height: 48px;
    }
    @media (max-width: 575px) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  h4 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;

    @media (min-width: 576px) and (max-width: 991px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 575px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;
