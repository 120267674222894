import {
  type ReportCallback,
  createTimedCallback,
  createNavigationCallback,
  mfeTree
} from 'src/utils/webProfiler';

import { PerformanceHandler } from './PerformanceHandler';

const LAYOUT_SHIFT_TIMEOUT = 2000;

/*
Microfrontend Layout Stabilization (MLS)

The goal of this metric is to measure the time it takes for a microfrontend
to complete its renderization since it was navigated to.

To detect if an MFE has completed its rendering, we rely on layout shifts,
because a burst of shifts on layout usually means that there are elements
still being loaded on the page.
Once the layout stabilizes for at least LAYOUT_SHIFT_TIMEOUT milliseconds,
we report the metric and stop counting.
*/

export class MLSHandler extends PerformanceHandler {
  private assetReference: string | null = null;
  private isListening: boolean = true;

  static setup(onMLS: ReportCallback): void {
    if (this.$instance) {
      return;
    }
    this.$instance = new MLSHandler(onMLS);
    this.$instance.observer?.observe({
      type: 'layout-shift',
      buffered: true
    });
  }

  constructor(onMLS: ReportCallback) {
    super(() => {
      const onStoppedShifting = createTimedCallback(() => {
        if (!this.isListening) {
          return;
        }
        try {
          performance.measure('MFENavigationPaint', {
            detail: this.assetReference,
            start: 'MFENavigated',
            end: 'MFELayoutStabilized'
          });
          const entries = performance.getEntriesByName('MFENavigationPaint');
          this.handle(entries);
          this.isListening = false;
        } catch (_e) {
          console.info('MLS could not be computed');
        }
      }, LAYOUT_SHIFT_TIMEOUT);

      performance.clearMarks('MFELayoutStabilized');
      performance.mark('MFELayoutStabilized');
      onStoppedShifting.reset();
    });

    this.onReport = onMLS;

    createNavigationCallback((ev) => {
      const { content } = ev?.eventData?.data || {};
      if (!content?.assetReference) {
        return;
      }

      this.clearMarks('MFENavigated', 'MFELayoutStabilized');
      this.isListening = true;
      this.assetReference = content.assetReference;

      performance.mark('MFENavigated');
    });
  }

  clearMarks(...marks: string[]): void {
    marks.forEach((mark) => performance.clearMarks(mark));
  }

  handle(entries: PerformanceEntry[]): void {
    const lastEntry = entries.slice(-1).pop() as MFELayoutStabilization;
    this.report({
      metric: 'MLS',
      tree: mfeTree.get(lastEntry.detail),
      userAgent: window.navigator.userAgent,
      value: lastEntry.duration,
      assetReference: lastEntry.detail,
      entries,
      timestamp: performance.now()
    });
  }
}
