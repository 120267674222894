import React from 'react';
import dependencyManagerContext from '../dependencyManagerContext';
// import resources from 'src/assets/locale';
import getCommonsNorthboundAPIs from '../utils/getCommonsNorthboundAPIs';
import * as T from '../types';

/**
 * This component is responsible to manage global dependencies and provide them to the children components
 * It should be used by the main component of your project
 * You can use the hook to get the dependencies in any child component
 * It is configured by default to be used inside MFE environment, but you can change it to be used in any other environment
 */
const DependencyManagerProvider: React.FC<
  T.DependencyManagerProviderPropsType
> = ({ children }) => {
  // const [globalTranslate, setGlobalTranslate] =
  //   React.useState<T.DependencyManagerContextType['globalTranslate']>();
  // const [translate, setTranslate] = React.useState<T.TranslateType | undefined>();

  const commonsNorthboundAPIs = React.useMemo(
    () => getCommonsNorthboundAPIs(),
    []
  );

  // Create a translator function by providing the resource files
  // React.useEffect(() => {
  //   commonsNorthboundAPIs.v1.localization
  //     .createTranslatorFunction(resources)
  //     .then((response) => setTranslate(() => response));
  // }, [commonsNorthboundAPIs.v1.localization]);

  // React.useEffect(() => {
  //   commonsNorthboundAPIs.v1.localization
  //     ?.getGlobalTranslatorFunction?.()
  //     ?.then((response) => setGlobalTranslate(() => response));
  // }, [commonsNorthboundAPIs.v1.localization]);

  return (
    <dependencyManagerContext.Provider
      value={{
        // globalTranslate,
        // translate,
        northboundAPIs: commonsNorthboundAPIs
      }}
    >
      {children}
    </dependencyManagerContext.Provider>
  );
};

export default DependencyManagerProvider;
