import React from 'react';
import * as S from './styles';
import { IconWarningAlt } from '@veneer/core';
import { ErrorComponentLoaderPropsType } from 'src/components/ErrorBoundary/ErrorComponentLoader/types';

const SmallErrorComponent: React.FC<ErrorComponentLoaderPropsType> = ({
  resetComponent
}) => {
  return (
    <S.Container>
      <S.Content
        style={{
          cursor: resetComponent ? 'pointer' : 'default'
        }}
        onClick={() => resetComponent?.()}
      >
        <IconWarningAlt />
        <span>Error</span>
      </S.Content>
    </S.Container>
  );
};

export default SmallErrorComponent;
