import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5;
  display: grid;
  place-items: center;
`;

export const Button = styled.button`
  appearance: none;
  border: 1px solid transparent;
  margin: 0px;
  overflow: visible;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  box-shadow: none;
  flex-shrink: 0;
  padding: calc(11px) calc(19px);
  position: relative;
  gap: 8px;
  border-radius: 12px;
  min-width: 112px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s,
    outline-color 0.2s;
  background-color: #0073a8;
  color: rgb(255, 255, 255);
  outline: transparent solid 2px;
  outline-offset: 2px;
`;

export const Content = styled.div`
  text-align: center;
  max-width: 38ch;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
`;
