import {
  environmentSupportsProfiling,
  type ObserverCallback,
  type ReportEntry
} from 'src/utils/webProfiler';

export abstract class PerformanceHandler {
  enabled: boolean | null = null;
  readonly reportsCache = [] as ReportEntry[];
  readonly observer: PerformanceObserver | null = null;
  onReport: (entry: ReportEntry) => void;

  static $instance: PerformanceHandler;

  abstract handle(entries: PerformanceEntry[]): void;

  constructor(cb: ObserverCallback) {
    if (environmentSupportsProfiling()) {
      this.observer = new PerformanceObserver(cb);
    }
  }

  static enable(): void {
    if (!this.$instance) {
      return console.warn(
        `No instance available for PerformanceHandler. Forgot to call .setup()?`
      );
    }
    this.$instance.enabled = true;

    while (this.$instance.reportsCache.length > 0) {
      const entry = this.$instance.reportsCache.shift();
      this.$instance.report(entry);
    }
  }

  static disable(): void {
    this.$instance.enabled = false;
    this.$instance.reportsCache.splice(0, this.$instance.reportsCache.length);
  }

  protected report(entry: ReportEntry): void {
    if (this.enabled === null) {
      this.reportsCache.push(entry);
    }
    if (this.enabled) {
      return this.onReport(entry);
    }
  }
}
