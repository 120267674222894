export class DefaultMap<K, V> extends Map<K, V> {
  private defaultValue: () => V;

  constructor(defaultValue: () => V) {
    super();
    this.defaultValue = defaultValue;
  }

  get(key: K): V {
    if (!this.has(key)) {
      this.set(key, this.defaultValue());
    }
    return super.get(key) as V;
  }
}
