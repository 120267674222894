import React from 'react';
import * as T from './types';
import { OptionalType } from 'src/types/typeHandlers';
import ErrorComponentLoader from './ErrorComponentLoader';
import { useMicrofrontendsRenderTreeManager } from 'src/contexts/microfrontendsRenderTreeManager';
import { useLogManager } from 'src/contexts/logManager';
import type { ComponentStackModuleOriginType } from 'src/contexts/logManager/types';
class ErrorBoundaryComponent extends React.Component<
  T.ErrorBoundaryComponentPropsType,
  T.ErrorBoundaryStateType
> {
  constructor(props: T.ErrorBoundaryComponentPropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars
    error: any
  ): OptionalType<T.ErrorBoundaryStateType> {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error: any, errorInfo?: { componentStack?: string }): void {
    try {
      const firstComponentName =
        this?.props?.microfrontendsRenderTreeManagerContext?.firstComponentName;
      const firstComponentDisplayName =
        this?.props?.microfrontendsRenderTreeManagerContext
          ?.firstComponentDisplayName;
      const branchOrigin =
        this?.props?.microfrontendsRenderTreeManagerContext?.getBranchOrigin?.();

      const componentStack: string[] =
        errorInfo?.componentStack
          ?.split?.(/\n\s*in\s/)
          ?.map?.((str) => str?.trim?.()?.split(' ')?.[0])
          ?.filter?.(Boolean) || [];

      const defaultAssetReference = 'unknownAssetReference';

      const componentStackModuleOrigin: ComponentStackModuleOriginType = {
        assetReference: branchOrigin?.assetReference || defaultAssetReference,
        branchLevel: branchOrigin?.branchLevel,
        componentStack: []
      };

      let currentComponentStackModuleReference = componentStackModuleOrigin;
      let currentComponentStackBranchReference = branchOrigin;

      componentStack.forEach((componentName) => {
        currentComponentStackModuleReference?.componentStack?.push(
          componentName
        );

        const parentBranch = currentComponentStackBranchReference?.parentBranch;
        const isFirstComponentName =
          componentName === firstComponentName ||
          componentName === firstComponentDisplayName;
        if (isFirstComponentName && parentBranch) {
          currentComponentStackBranchReference = parentBranch;
          const parentComponentStack: ComponentStackModuleOriginType = {
            assetReference:
              parentBranch.assetReference || defaultAssetReference,
            branchLevel: parentBranch.branchLevel,
            componentStack: []
          };
          currentComponentStackModuleReference.parentComponentStack =
            parentComponentStack;
          currentComponentStackModuleReference = parentComponentStack;
        }
      });

      this?.props?.logManagerContext?.logError?.({
        componentStack,
        componentStackModuleOrigin,
        error
      });
    } catch (error) {
      this?.props?.logManagerContext?.logError?.({ error });
    }
  }

  resetComponent = (): void => {
    this.setState({
      hasError: false
    });
  };

  render(): any {
    if (this.props.showError) {
      return <ErrorComponentLoader />;
    } else if (this.state.hasError) {
      return <ErrorComponentLoader resetComponent={this.resetComponent} />;
    }

    return <>{this.props.children}</>;
  }
}

const ErrorBoundary: React.FC<T.ErrorBoundaryPropsType> = (props) => {
  const microfrontendsRenderTreeManagerContext =
    useMicrofrontendsRenderTreeManager();
  const logManagerContext = useLogManager();

  return (
    <ErrorBoundaryComponent
      {...props}
      microfrontendsRenderTreeManagerContext={
        microfrontendsRenderTreeManagerContext
      }
      logManagerContext={logManagerContext}
    />
  );
};

export default ErrorBoundary;
