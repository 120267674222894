import {
  type ReportCallback,
  groupEntriesByAssetReference,
  mfeTree
} from 'src/utils/webProfiler';

import { DefaultMap } from 'src/utils/DefaultMap';
import { PerformanceHandler } from './PerformanceHandler';

// Important consideration: google's original implementation only measures
// CLS after FCP. However the paint timing API does not provide information
// about the DOM node at which the event was triggered, hence it cannot be
// adapted to a microfrontend level.

export class CLSHandler extends PerformanceHandler {
  private entries = new DefaultMap<string, LayoutShift[]>(() => []);
  private values = new DefaultMap<string, number>(() => 0);
  private lastReportedValues = new DefaultMap<string, number>(() => 0);
  private lastReportedEntries = new DefaultMap<string, LayoutShift[]>(() => []);

  static setup(onCLS: ReportCallback): void {
    if (this.$instance) {
      return;
    }
    this.$instance = new CLSHandler(onCLS);
    this.$instance.observer?.observe({
      type: 'layout-shift',
      buffered: true
    });
  }

  constructor(onCLS: ReportCallback) {
    super((list) => {
      CLSHandler.$instance?.handle(Array.from(list.getEntries()));
    });
    this.onReport = onCLS;
  }
  processEntry(entry: LayoutShift, mfe: string): void {
    if (entry.hadRecentInput) {
      return;
    }
    const firstEntryStartTime = this.entries.has(mfe)
      ? this.entries.get(mfe)[0].startTime
      : Number.POSITIVE_INFINITY;
    const lastEntryStartTime = this.entries.has(mfe)
      ? this.entries.get(mfe).slice(-1).pop().startTime
      : Number.NEGATIVE_INFINITY;

    // Google batches events that happen within a 1-5 second window.
    if (
      entry.startTime - lastEntryStartTime < 1000 &&
      entry.startTime - firstEntryStartTime < 5000
    ) {
      this.values.set(mfe, this.values.get(mfe) + entry.value);
      this.entries.get(mfe).push(entry);
    } else {
      this.values.set(mfe, entry.value);
      this.entries.set(mfe, [entry]);
    }
  }
  reportIfNeeded(assetReference: string): void {
    if (
      this.values.get(assetReference) >
      this.lastReportedValues.get(assetReference)
    ) {
      this.lastReportedValues.set(
        assetReference,
        this.values.get(assetReference)
      );
      this.lastReportedEntries.set(
        assetReference,
        this.entries.get(assetReference)
      );

      this.report({
        metric: 'CLS',
        tree: mfeTree.get(assetReference),
        userAgent: window.navigator.userAgent,
        value: this.lastReportedValues.get(assetReference),
        entries: this.lastReportedEntries.get(assetReference),
        assetReference,
        timestamp: performance.now()
      });
    }
  }
  handle(entriesList: PerformanceEntry[]): void {
    const group = groupEntriesByAssetReference(entriesList);

    for (const [assetReference, entries] of group.entries()) {
      for (const entry of entries as LayoutShift[]) {
        this.processEntry(entry, assetReference);
      }
    }
    for (const assetReference of group.keys()) {
      this.reportIfNeeded(assetReference);
    }
  }
}
