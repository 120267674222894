/*
 * source: https://github.com/GoogleChrome/web-vitals/blob/main/src/lib/bfcache.ts
 */

interface onBFCacheRestoreCallback {
  (event: PageTransitionEvent): void;
}

let bfcacheRestoreTime = -1;

export const getBFCacheRestoreTime = (): number => bfcacheRestoreTime;

export const onBFCacheRestore = (cb: onBFCacheRestoreCallback): void => {
  addEventListener(
    'pageshow',
    (event) => {
      if (event.persisted) {
        bfcacheRestoreTime = event.timeStamp;
        cb(event);
      }
    },
    true
  );
};
