/*
 * source: https://github.com/GoogleChrome/web-vitals/blob/main/src/lib/runOnce.ts
 */

export const runOnce = (cb: () => void): (() => void) => {
  let called = false;
  return () => {
    if (!called) {
      cb();
      called = true;
    }
  };
};
